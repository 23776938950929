<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Anekdotal</h5>
              <router-link to="/anekdotal/tambah"
                class="btn btn-primary btn-sm">Tambah</router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                 
                  <tr style="max-height: 5px">
                    <th class="text-center">Data </th>
                  
                    <th class="text-center">Peristiwa</th>
                    <th class="text-center"> Guru</th>
                    <th class="text-center"> BK</th>
                    <th class="text-center"> Kesiswaan</th>
                    <th class="text-center">Catatan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in anekdotals.data" :key="m.id" style="height: 1px">
                    <td><b>{{m.fullname}}</b> <br/>{{ m.desc }} <br/> {{ m.nama_unit }}-{{ m.nama_kelas }} <br/> {{ formatTanggal(m.tanggal_peristiwa) }}
                    <br/><badge class="badge badge-danger">{{ m.kategori_peristiwa }}</badge><br/><br/>
                    <router-link 
                          :to="{ name: 'admin-anekdotal-edit', params: { id: m.id }, query: { idunit: m.id_unit } }"
                          class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>
                        <button v-if="datapermissions.includes('aturanpenilaian-delete')"
                          class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.id)"> <i class="fas fa-trash"></i>
                        </button>
                    </td>
                   
                    <td class="text-center"><textarea class="form-control" :value="m.peristiwa" @input="updateData(m.id, 'peristiwa' ,$event)"  :readonly="m.tindakan_bk !== null || m.tindakan_kesiswaan !== null">
                    
                  </textarea></td>
                    <td class="text-center">
                    <textarea class="form-control"  :value="m.tindakan_guru" @input="updateData(m.id, 'tindakan_guru' ,$event)"  :readonly="m.tindakan_bk !== null || m.tindakan_kesiswaan !== null" >
                    
                    </textarea>
                  </td>
                
                    <td class="text-center">
                      
                      <textarea class="form-control" :value="m.tindakan_bk" @input="updateData(m.id, 'tindakan_bk' ,$event)" :readonly="user.roles[0].name == 'wali kelas' || user.roles[0].name == 'akademik'">
                    
                  </textarea>
                    </td>
                    <td class="text-center">
                      <textarea class="form-control" :value="m.tindakan_kesiswaan" @input="updateData(m.id, 'tindakan_kesiswaan' ,$event)" :readonly="user.roles[0].name == 'wali kelas' || user.roles[0].name == 'akademik'">
                    
                  </textarea>
                    </td>
                    <td class="text-center"><textarea class="form-control" :value="m.catatan" @input="updateData(m.id, 'catatan' ,$event)">
                    
                  </textarea></td>

                  
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ anekdotals.to }} dari {{ anekdotals.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: anekdotals.current_page === 1 }">
                  <a class="page-link" @click="changePage(anekdotals.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in anekdotals.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: anekdotals.current_page === anekdotals.last_page }">
                  <a class="page-link" @click="changePage(anekdotals.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive,ref } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";

export default {
  name: "anekdotalIndex",

  methods: {
    formatTanggal(tanggal) {
      if (!tanggal) return "-"; // Jika tidak ada tanggal, tampilkan "-"
      
      const bulanIndo = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
      ];

      const dateObj = new Date(tanggal);
      if (isNaN(dateObj)) return "-"; // Handle jika tanggal tidak valid

      const hari = dateObj.getDate();
      const bulan = bulanIndo[dateObj.getMonth()];
      const tahun = dateObj.getFullYear();

      return `${hari} ${bulan} ${tahun}`;
    }
  },


  setup() {
    const store = useStore();
    const validation = ref([]);
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      pages: 'anekdotal-view',
      tindakan_guru:'',
      tindakan_bk:'',
      tindakan_kesiswaan:''

    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("anekdotal/getData");
      await store.dispatch("user/getDataPermission");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const anekdotals = computed(() => {
      return store.getters["anekdotal/getData"];
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("anekdotal/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian

      });
    };

    async function updateData(id, jenis, event) {
      let formData = new FormData();
      formData.append(jenis, event.target.value)
      await store.dispatch("anekdotal/updateTindakan", {
          anekdotalId: id,
          payload: formData,
        })
        .then(() => {

        })
        .catch((error) => {

          validation.value = error;

          Swal.fire({
            title: "GAGAL!",
            text: validation.value.error,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    }

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("anekdotal/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("anekdotal/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('anekdotal/destroy', id)
            .then(() => {

              store.dispatch("anekdotal/getData");
              // 
              // router.push({ name: "admin-anekdotal" });
              router.push({ name: "admin-anekdotal" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })



            })
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      anekdotals,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      datapermissions,
      updateData,
      user

    };
  },
};
</script>