<template>
  <div class="container-fluid p-5">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h3>Input Nilai Angka</h3>
             
              <div class="d-flex gap-3">
                <p>Nama Pengajar :{{ guru.fullname }}</p>
                <router-link
                  :to="{ name: 'admin-penilaian' }"
                  class="btn btn-sm btn-primary"
                  >Kembali</router-link
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleFormControlInput1"
                    >Pilih kelas yang diajar:<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <select
                    class="form-control"
                    v-model="data.kelasid"
                    @change="changeSiswa"
                  >
                    <option value="">-- select kelas --</option>
                    <option
                      v-for="k in kelas"
                      :key="k.id"
                      :value="k.id"
                      :id-ta="k.id_ta"
                      :id-mapel="k.idmapel"
                      :jenis-penilaian="k.jenis_penilaian"
                      :id-unit="k.id_unit"
                    >
                      {{ k.classname }} - {{ k.nama_mapel }}
                    </option>
                  </select>
                </div>

                <div class="form-group">

                  <a  v-if="showButton"
                          :href="`${this.$baseURL}/export-nilai?idkelas=${data.kelasid}&id=${data.idmapel}&idunit=${data.idunit}&pengajarid=${$route.params.id}`"
                          target="_blank" class="btn btn-sm btn-primary">
                          Export
                        </a>

              &nbsp;
              <button v-if="showButton" class="btn btn-sm btn-success" @click="refresh" :disabled="isLoading">
                  <span v-if="isLoadingButton">Loading...</span>
                  <span v-else>Refresh </span>
                </button>
                </div>

                <div class="mt-5" v-if="data.kelasid > 0">
                  <div v-if="isLoading">
                    <ContentLoader />
                  </div>
                  <div class="table-responsive table-container" v-else>
                    <table id="datatable-search" class="table table-bordered">
                      <thead class="thead-light">
                        <tr>
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                              position: sticky;
                              left: 0;
                              top: 0;
                              background-color: #f2f2f2;
                              z-index: 2;
                            "
                          >
                            Nama
                          </th>
                          <template v-for="ranah in kategori">
                            <th
                              v-for="n in ranah.jumlah_generate"
                              :key="n"
                              style="
                                font-size: 10pt;
                                color: gray;
                                font-weight: bold;
                              "
                              align="center"
                            >
                              {{ ranah.nama_subkategoripenilaian
                              }}{{ ranah.jumlah_generate !== 1 ? n : "" }}
                            </th>
                          </template>
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                            "
                            align="center"
                          >
                            P
                          </th>
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                            "
                            align="center"
                          >
                            K
                          </th>
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                            "
                            align="center"
                          >
                            NM
                          </th>
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                            "
                            align="center"
                          >
                            NP
                          </th>
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                            "
                            align="center"
                          >
                            NA
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="m in siswa" :key="m.id">
                          <td
                            style="
                              font-size: 10pt;
                              color: gray;
                              position: sticky;
                              left: 0;
                              background-color: #fff;
                              z-index: 3;
                            "
                          >
                            {{ m.fullname }}
                          </td>
                          <template v-for="ranah in kategori">
                            <td
                              v-for="(n, index) in ranah.jumlah_generate"
                              :key="index"
                              align="center"
                              style="
                                background-color: #fff;
                                z-index: -2;
                                max-width: 30px;
                              "
                            >
                              <input
                                class="form-nilai"
                                type="number"
                                min="0"
                                max="100"
                                style="
                                  text-align: center;
                                  max-width: 30px;
                                  font-size: 10pt;
                                  color: gray;
                                "
                                @keydown.enter="
                                  handleNilai($event, n, index, ranah, m)
                                "
                                :value="
                                  getNilai(
                                    m.studentid,
                                    ranah.id,
                                    ranah.id_sub,
                                    n
                                  )
                                "
                              />
                            </td>
                          </template>
                          <td
                            align="center"
                            style="
                              text-align: center;
                              max-width: 30px;
                              font-size: 10pt;
                              color: gray;
                            "
                          >
                            {{
                              Math.round(
                                getNilaiP(m.studentid) == null
                                  ? ""
                                  : getNilaiP(m.studentid)
                              )
                            }}
                          </td>
                          <td
                            align="center"
                            style="
                              text-align: center;
                              max-width: 30px;
                              font-size: 10pt;
                              color: gray;
                            "
                          >
                            {{
                              Math.round(
                                getNilaiK(m.studentid) == null
                                  ? ""
                                  : getNilaiK(m.studentid)
                              )
                            }}
                          </td>
                          <td
                            align="center"
                            style="
                              text-align: center;
                              max-width: 30px;
                              font-size: 10pt;
                              color: gray;
                            "
                          >
                            {{
                              Math.round(
                                getNilaiNM(m.studentid) == null
                                  ? ""
                                  : getNilaiNM(m.studentid)
                              )
                            }}
                            
                          </td>
                          <td
                            align="center"
                            style="
                              text-align: center;
                              max-width: 30px;
                              font-size: 10pt;
                              color: gray;
                            "
                          >
                          <input
                                class="form-nilai"
                                type="number"
                                min="0"
                                max="100"
                                style="
                                  text-align: center;
                                  max-width: 30px;
                                  font-size: 10pt;
                                  color: gray;
                                "
                                @keydown.enter="
                                  handlePernyataan($event, m)
                                "
                                :value="
                                  getNilaiNP(
                                    m.studentid
                                  )
                                "
                              />
                            
                          </td>
                          
                          <td
                            align="center"
                            style="
                              text-align: center;
                              max-width: 30px;
                              font-size: 10pt;
                              color: gray;
                            "
                          >
                          <p  style="
                                  text-align: center;
                                  max-width: 30px;
                                  font-size: 10pt;
                                  color: gray;
                                " v-if="getNilaiNP(
                                    m.studentid
                                  )">{{ getNilaiNP(
                                    m.studentid
                                  ) }}</p>

                                  <p  style="
                                  text-align: center;
                                  max-width: 30px;
                                  font-size: 10pt;
                                  color: gray;
                                " v-else>
{{
                              Math.round(
                                getNilaiNA(m.studentid) == null
                                  ? ""
                                  : getNilaiNA(m.studentid)
                              )
                            }}
                                  </p>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { computed, reactive, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ContentLoader } from "vue-content-loader";
import Swal from "sweetalert2";

export default {
  name: "inputnilai",
  components: {
    ContentLoader,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const validation = ref([]);
    const data = reactive({
      kelasid: "",
      pages: "perwalian-view",
      idunit: "",
      jenis: "",
      jenisPenilaian: "",
      idmapel: "",
    });
    const guru = reactive({
      fullname: "",
    });
    const isLoading = ref(false); // Use ref for a reactive boolean
    const showButton = ref(false); 
    const isLoadingButton = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      try {
        await store.dispatch("guru/getDetail", route.params.id);
        await store.dispatch("bebanmengajar/getKelasGuruData", route.params.id);
        await store.dispatch("kategoripenilaian/getKategori");
      } catch (error) {
        // console.error("Failed to fetch kelas data:", error);
      }

      // Set a timeout to simulate loading delay (e.g., 2 seconds)
      setTimeout(() => {
        isLoading.value = false;
      }, 30000);
    });

    const kelas = computed(() => {
      return store.getters["bebanmengajar/getKelasGuruData"];
    });

    const kategori = computed(() => {
      return store.getters["kategoripenilaian/getKategori"];
    });

    const siswa = computed(() => {
      return store.getters["bebanmengajar/getDataSiswaAktifMapel"];
    });

    const nilais = computed(() => {
      return store.getters["nilai/getData"];
    });

    const nilaip = computed(() => {
      return store.getters["nilai/getDataP"];
    });

    const nilaik = computed(() => {
      return store.getters["nilai/getDataK"];
    });

    const nilainm = computed(() => {
      return store.getters["nilai/getDataNM"];
    });

    const nilaina = computed(() => {
      return store.getters["nilai/getDataNA"];
    });

    const nilainp = computed(() => {
      return store.getters["nilai/getDataNP"];
    });

    const jenissemesteraktif = computed(() => {
      return store.getters["aturannilai/getAktif"];
    });

    const getNilai = (idSiswa, idKategori, idSubkategori, order) => {
      const nilai = nilais.value.find((item) => {
        return (
          item.siswa_id === idSiswa &&
          item.id_kategori === idKategori &&
          item.id_subkategorinilai === idSubkategori &&
          item.order === order
        );
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiP = (idSiswa) => {
      const nilai = nilaip.value.find((item) => {
        return item.siswa_id === idSiswa;
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiK = (idSiswa) => {
      const nilai = nilaik.value.find((item) => {
        return item.siswa_id === idSiswa;
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiNM = (idSiswa) => {
      const nilai = nilainm.value.find((item) => {
        // console.log(item);
        return item.siswa_id === idSiswa;
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiNA = (idSiswa) => {
      const nilai = nilaina.value.find((item) => {
        return item.siswa_id === idSiswa;
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiNP = (idSiswa) => {
      const nilai = nilainp.value.find((item) => {
        return item.siswa_id === idSiswa;
      });

      return nilai ? nilai.nilai : "";
    };

    const changeSiswa = async (event) => {
      isLoading.value = true;
      showButton.value = true;
      try {
        data.jenisPenilaian =
          event.target.options[event.target.selectedIndex].getAttribute(
            "jenis-penilaian"
          );
        data.idunit =
          event.target.options[event.target.selectedIndex].getAttribute(
            "id-unit"
          );

        data.idmapel =
          event.target.options[event.target.selectedIndex].getAttribute(
            "id-mapel"
          );
        const selectedIdUnit =
          event.target.options[event.target.selectedIndex].getAttribute(
            "id-unit"
          );
        await store.dispatch(
          "bebanmengajar/getDataSiswaAktifMapel",
          data.kelasid
        );
        await store.dispatch("kategoripenilaian/getKategori", data.kelasid);
        await store.dispatch("nilai/getData", data.idmapel);
        await store.dispatch("nilai/getDataP", {
          idmapel: data.idmapel,
          idunit: selectedIdUnit,
        });
        await store.dispatch("nilai/getDataK", {
          idmapel: data.idmapel,
          idunit: selectedIdUnit,
        });
        await store.dispatch("nilai/getDataNM", {
          idmapel: data.idmapel,
          idunit: selectedIdUnit,
        });
        await store.dispatch("nilai/getDataNA", {
          idmapel: data.idmapel,
          idunit: selectedIdUnit,
        });
        await store.dispatch("nilai/getDataNP", {
          idmapel: data.idmapel,
          idunit: selectedIdUnit,
        });
        await store.dispatch("aturannilai/getAktif", selectedIdUnit);

      } catch (error) {
        console.error("Failed to fetch siswa data:", error);
      }
      // Set a timeout to simulate loading delay (e.g., 2 seconds)
      setTimeout(() => {
        isLoading.value = false;
      }, 7000);
    };

    const focusNextInput = (e) => {
      var total = 0;

      // Loop melalui setiap objek dalam kategori.value dan tambahkan jumlahnya ke total
      for (var i = 0; i < kategori.value.length; i++) {
        total += kategori.value[i].jumlah_generate;
      }

      // Hasilnya akan disimpan dalam variabel 'total'
      // console.log("Total jumlah generate:", total);

      // const posisi = index;

      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-nilai");
        const currentIndex = Array.from(inputs).indexOf(targetInput);
        const nextIndex = currentIndex + (total + 1);

        if (nextIndex >= 0 && nextIndex < inputs.length) {
          inputs[nextIndex].focus();
        } else {
          const nextIndex = currentIndex + 1;
          inputs[nextIndex].focus();
          // Jika nextIndex di luar rentang, lakukan penanganan yang sesuai
          // console.log('Elemen tidak ditemukan atau indeks di luar rentang.');
          // Atau lakukan sesuatu yang lain sesuai kebutuhan aplikasi Anda
        }
      }
    };

    const refresh = async () => {
      isLoadingButton.value = true; // Set loading to true

      try {
        await store.dispatch("nilai/getData", data.idmapel);
        await store.dispatch("nilai/getDataP", {
          idmapel: data.idmapel,
          idunit: data.idunit,
        });
        await store.dispatch("nilai/getDataK", {
          idmapel: data.idmapel,
          idunit: data.idunit,
        });
        await store.dispatch("nilai/getDataNM", {
          idmapel: data.idmapel,
          idunit: data.idunit,
        });
        await store.dispatch("nilai/getDataNA", {
          idmapel: data.idmapel,
          idunit: data.idunit,
        });
        await store.dispatch("nilai/getDataNP", {
          idmapel: data.idmapel,
          idunit: data.idunit,
        });
      
      } catch (error) {
        console.error('Error during refresh:', error);
      } finally {
        isLoadingButton.value = false; // Set loading to false
      }
    };

    async function updateData(e, n, index, ranah, m) {
      const inputValue = e.target.value;
      console.log("values", ranah);

      if (inputValue != "") {
        if (inputValue > 100) {
          Swal.fire({
            title: "GAGAL!",
            text: "nilai maksimal 100",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (inputValue < 0) {
          Swal.fire({
            title: "GAGAL!",
            text: "nilai diantara 0 sd 100",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          if (inputValue.length != 0) {
            let formData = new FormData();

          
            formData.append("nilai", e.target.value);
            formData.append("id", ranah.id);
            formData.append("order", n);
            formData.append("id_siswa", m.studentid);
            formData.append("id_subkategorinilai", ranah.id_sub);
            formData.append("id_kategori", ranah.id_kategoripenilaian);
            formData.append("bebanmengajar_id", data.kelasid);
            formData.append("id_mapel", data.idmapel);
            formData.append("unit", data.idunit);
            formData.append("jenis_semester", data.jenis);
            formData.append("jenis_penilaian", data.jenisPenilaian);

            await store
              .dispatch("nilai/store", formData)
              .then(() => {
                // store.dispatch("nilai/getData", data.idmapel);
                // store.dispatch("nilai/getDataP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataK", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNM", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNA", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
              })
              .catch((error) => {
                validation.value = error;

                Swal.fire({
                  title: "GAGAL!",
                  text: validation.value.error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          } else if (inputValue.trim() === "") {
            // console.log('trim', e.target.attributes[4].nodeValue);
            let formData = new FormData();

            formData.append("nilai", e.target.value);
            formData.append("id", ranah.id);
            formData.append("order", n);
            formData.append("id_siswa", m.studentid);
            formData.append("id_subkategorinilai", ranah.id_sub);
            formData.append("id_kategori", ranah.id_kategoripenilaian);
            formData.append("bebanmengajar_id", data.kelasid);
            formData.append("id_mapel", data.idmapel);
            formData.append("unit", data.idunit);
            formData.append("jenis_semester", data.jenis);
            formData.append("jenis_penilaian", data.jenisPenilaian);

            await store
              .dispatch("nilai/store", formData)
              .then(() => {
                // store.dispatch("nilai/getData", data.idmapel);
                // store.dispatch("nilai/getDataP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataK", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNM", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNA", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
              })
              .catch((error) => {
                validation.value = error;

                Swal.fire({
                  title: "GAGAL!",
                  text: validation.value.error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          } else if (inputValue.length == 0) {
            let formData = new FormData();

            // console.log('data-id', e.target);
            formData.append("nilai", e.target.value);
            formData.append("id", ranah.id);
            formData.append("order", n);
            formData.append("id_siswa", m.studentid);
            formData.append("id_subkategorinilai", ranah.id_sub);
            formData.append("id_kategori", ranah.id_kategoripenilaian);
            formData.append("id_kategori", e.target.attributes[9].nodeValue);
            formData.append("bebanmengajar_id", data.kelasid);
            formData.append("id_mapel", data.idmapel);
            formData.append("unit", data.idunit);
            formData.append("jenis_semester", data.jenis);
            formData.append("jenis_penilaian", data.jenisPenilaian);

            await store
              .dispatch("nilai/store", formData)
              .then(() => {
                // store.dispatch("nilai/getData", data.idmapel);
                // store.dispatch("nilai/getDataP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataK", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNM", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNA", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
              })
              .catch((error) => {
                validation.value = error;

                Swal.fire({
                  title: "GAGAL!",
                  text: validation.value.error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          }
        }
      } else {
        let formData = new FormData();

        // console.log('data-id', e.target);
        formData.append("nilai", e.target.value);
        formData.append("id", ranah.id);
        formData.append("order", n);
        formData.append("id_siswa", m.studentid);
        formData.append("id_subkategorinilai", ranah.id_sub);
        formData.append("id_kategori", ranah.id_kategoripenilaian);
        formData.append("bebanmengajar_id", data.kelasid);
        formData.append("unit", data.idunit);
        formData.append("id_mapel", data.idmapel);
        formData.append("jenis_semester", data.jenis);
        formData.append("jenis_penilaian", data.jenisPenilaian);

        await store
          .dispatch("nilai/store", formData)
          .then(() => {
            // store.dispatch("nilai/getData", data.idmapel);
            // store.dispatch("nilai/getDataP", {
            //   idmapel: data.idmapel,
            //   idunit: data.idunit,
            // });
            // store.dispatch("nilai/getDataK", {
            //   idmapel: data.idmapel,
            //   idunit: data.idunit,
            // });
            // store.dispatch("nilai/getDataNM", {
            //   idmapel: data.idmapel,
            //   idunit: data.idunit,
            // });
            // store.dispatch("nilai/getDataNA", {
            //   idmapel: data.idmapel,
            //   idunit: data.idunit,
            // });
            // store.dispatch("nilai/getDataNP", {
            //   idmapel: data.idmapel,
            //   idunit: data.idunit,
            // });
          })
          .catch((error) => {
            validation.value = error;

            Swal.fire({
              title: "GAGAL!",
              text: validation.value.error,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    }

    async function updateDataPernyataan(e,m) {
      const inputValue = e.target.value;
      const inputElement = e.target;
      if (inputValue != "") {
        if (inputValue > 100) {
          Swal.fire({
            title: "GAGAL!",
            text: "nilai maksimal 100",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (inputValue < 0) {
          Swal.fire({
            title: "GAGAL!",
            text: "nilai diantara 0 sd 100",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          if (inputValue.length) {
            let formData = new FormData();

            if (inputElement && inputElement.hasAttribute("data-id")) {
              formData.append("nilai", e.target.value);
              formData.append("order", 1);
              formData.append("id_siswa", m.studentid);
              formData.append("id_subkategorinilai", null);
              formData.append("id_kategori", null);
              formData.append("bebanmengajar_id", data.kelasid);
              formData.append("id_mapel", data.idmapel);
              formData.append("unit", data.idunit);
              formData.append("jenis_semester", data.jenis);
              formData.append("jenis_penilaian", data.jenisPenilaian);
            } else {
              formData.append("nilai", e.target.value);
              formData.append("order", 1);
              formData.append("id_siswa", m.studentid);
              formData.append("id_subkategorinilai", null);
              formData.append("id_kategori", null);
              formData.append("bebanmengajar_id", data.kelasid);
              formData.append("id_mapel", data.idmapel);
              formData.append("unit", data.idunit);
              formData.append("jenis_semester", data.jenis);
              formData.append("jenis_penilaian", data.jenisPenilaian);
            }

            await store
              .dispatch("nilaipernyataan/store", formData)
              .then(() => {
                // store.dispatch("nilai/getData", data.idmapel);
                // store.dispatch("nilai/getDataP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataK", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNM", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNA", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
                // store.dispatch("nilai/getDataNP", {
                //   idmapel: data.idmapel,
                //   idunit: data.idunit,
                // });
              })
              .catch((error) => {
                validation.value = error;

                Swal.fire({
                  title: "GAGAL!",
                  text: validation.value.error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          } else if (inputValue.trim() === "") {
            // console.log('trim', e.target.attributes[4].nodeValue);
            let formData = new FormData();
            if (inputElement && inputElement.hasAttribute("data-id")) {
              formData.append("nilai", e.target.value);
              formData.append("order", 1);
              formData.append("id_siswa", m.studentid);
              formData.append("id_subkategorinilai", null);
              formData.append("id_kategori", null);
              formData.append("bebanmengajar_id", data.kelasid);
              formData.append("id_mapel", data.idmapel);
              formData.append("unit", data.idunit);
              formData.append("jenis_semester", data.jenis);
              formData.append("jenis_penilaian", data.jenisPenilaian);
            } else {
              formData.append("nilai", e.target.value);
              formData.append("order", 1);
              formData.append("id_siswa", m.studentid);
              formData.append("id_subkategorinilai", null);
              formData.append("id_kategori", null);
              formData.append("bebanmengajar_id", data.kelasid);
              formData.append("id_mapel", data.idmapel);
              formData.append("unit", data.idunit);
              formData.append("jenis_semester", data.jenis);
              formData.append("jenis_penilaian", data.jenisPenilaian);
            }
            await store
              .dispatch("nilaipernyataan/store", formData)
              .then(() => {
                store.dispatch("nilai/getData", data.idmapel);
                store.dispatch("nilai/getDataP", {
                  idmapel: data.idmapel,
                  idunit: data.idunit,
                });
                store.dispatch("nilai/getDataK", {
                  idmapel: data.idmapel,
                  idunit: data.idunit,
                });
                store.dispatch("nilai/getDataNM", {
                  idmapel: data.idmapel,
                  idunit: data.idunit,
                });
                store.dispatch("nilai/getDataNA", {
                  idmapel: data.idmapel,
                  idunit: data.idunit,
                });
                store.dispatch("nilai/getDataNP", {
                  idmapel: data.idmapel,
                  idunit: data.idunit,
                });
              })
              .catch((error) => {
                validation.value = error;

                Swal.fire({
                  title: "GAGAL!",
                  text: validation.value.error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          }
        }
      }
    }

    const handleNilai = (event, n, index, ranah, m) => {
      updateData(event, n, index, ranah, m);
      focusNextInput(event, n, index, ranah, m);
    };

    const handlePernyataan = (event, m) => {
        updateDataPernyataan(event,m);
        focusNextInput(event, m);
    }

    watch(
      jenissemesteraktif,
      (newVal) => {
        if (newVal == null) {
          data.jenis = null;
        } else {
          data.jenis = newVal.jenis_penilaian_aktif;
        }
      },
      { immediate: true }
    );

    watch(
      () => store.state.guru.details,
      (newguru) => {
        //console.log('newguru',newguru);
        if (newguru) {
          const {
            fullname,
            nickname,
            gender,
            birthplace,
            birthdate,
            marital_status,
            wni,
            religion,
            edulevel,
            address,
            id_province,
            id_city,
            id_district,
            id_subdistrict,
            cellphone,
            email,
            lang,
            nuptk,
          } = newguru;
          guru.fullname = fullname;
          guru.nickname = nickname;
          guru.gender = gender;
          guru.birthplace = birthplace;
          guru.birthdate = birthdate;
          guru.marital_status = marital_status;
          guru.wni = wni;
          guru.religion = religion;
          guru.edulevel = edulevel;
          guru.address = address;
          guru.id_province = id_province;
          guru.id_city = id_city;
          guru.id_district = id_district;
          guru.id_subdistrict = id_subdistrict;
          guru.cellphone = cellphone;
          guru.email = email;
          guru.lang = lang;
          guru.nuptk = nuptk;
        }
      }
    );

    return {
      data,
      kelas,
      nilais,
      changeSiswa,
      siswa,
      isLoading,
      showButton,
      kategori,
      getNilai,
      updateData,
      handleNilai,
      jenissemesteraktif,
      guru,
      getNilaiP,
      getNilaiK,
      getNilaiNM,
      getNilaiNA,
      getNilaiNP,
      updateDataPernyataan,
      handlePernyataan,
      refresh,
      isLoadingButton
    };
  },
};
</script>
  