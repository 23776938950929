<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Daftar Aspek Sikap</h5>
              <div class="d-flex">
                <router-link v-if="datapermissions.includes('kategorisikap-create')" to="/kategorisikap/tambah"
                class="btn btn-primary btn-sm">Tambah</router-link>
                <router-link v-if="datapermissions.includes('kategorisikap-create')  && user.roles[0].name == 'admin'" to="/kategorisikap/generate"
                class="btn btn-success btn-sm">Generate</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="">-- select tahun ajaran --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive" v-if="kategorisikaps.data">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Unit</th>
                    <!-- <th>Tahun Ajaran</th> -->
                    <th>Kategori</th>
                    <th>Aspek Sikap</th>
                    <th>Keterangan</th>
                    <th class="text-center">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in kategorisikaps.data" :key="m.id" style="height: 1px">
                    <td>{{ m.level }}</td>
                    <!-- <td>{{ m.desc }}</td> -->
                    <td>{{ m.groupsikap ? m.groupsikap.nama_group : '' }}</td>
                    <td>{{ m.nama_kategori }}</td>
                    <td>{{ m.type_kategori == 'mapel' ? 'Sikap Mata Pelajaran' : 'Sikap Non Mata Pelajaran' }}</td>

                    <td class="text-center">
                      <router-link v-if="datapermissions.includes('kategorisikap-edit')"
                        :to="{ name: 'admin-kategorisikap-edit', params: { id: m.id } }"
                        class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>
                      <button v-if="datapermissions.includes('kategorisikap-delete')" class="btn btn-danger btn-xs m-0"
                        @click="confirmDelete(m.id)"> <i class="fas fa-trash"></i> </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <ContentLoader />
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ kategorisikaps.to }} dari {{ kategorisikaps.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: kategorisikaps.current_page === 1 }">
                  <a class="page-link" @click="changePage(kategorisikaps.current_page - 1)" href="#"
                    aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in kategorisikaps.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: kategorisikaps.current_page === kategorisikaps.last_page }">
                  <a class="page-link" @click="changePage(kategorisikaps.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter, useRoute } from "vue-router";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "kategorisikapIndex",
  components: {
    ContentLoader,
  },


  setup() {
    const store = useStore();
    const route = useRoute();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      pages: "kategorisikap-view"

    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("kategorisikap/getData");
      await store.dispatch("user/getDataPermission");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("tahunajaran/getActive");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const kategorisikaps = computed(() => {
      return store.getters["kategorisikap/getData"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });
    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("kategorisikap/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta

      });
    };

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("kategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta
      });
    };

    
    const changeTa = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("kategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta


      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("kategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    watch(
      () => store.state.tahunajaran.active,
      (newactive) => {
        if (newactive) {
          const {
            id,


          } = newactive;
          data.ta = route.query.id ?? id;


        }
      }
    );


    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('kategorisikap/destroy', id)
            .then(() => {

              store.dispatch("kategorisikap/getData");
              // 
              // router.push({ name: "admin-kategorisikap" });
              router.push({ name: "admin-kategorisikap" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })



            })
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      kategorisikaps,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      datapermissions,
      changeTa,
      tas,
      user,
      tasaktif

    };
  },
};
</script>
