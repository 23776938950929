<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Laporan Perkembangan Bulanan KB-TK</h5>

              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                 
                  

                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Identitas Siswa </h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                                <td >Nama Lengkap</td>
                                                <td>:</td>
                                                <td>{{ siswa.data[0].fullname }}</td>
                                            </tr>
                                            <tr>
                                                <td >Jenis Laporan</td>
                                                <td >:</td>
                                                <td v-if="this.$route.params.bidang == 'wali kelas'">{{  this.$route.params.bidang }}</td>
                                                <td v-if="this.$route.params.bidang != 'wali kelas'">
                                                  Guru Bidang - {{  narasi.elemen }}
                                                </td>
                                            </tr>
                                          </thead>
                                        </table>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="saveLaporan">
                      <div class="row m-3">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="">Penilaian Perkembangan</label>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Semester</th>
                                  <th>
                                    {{ tahunajaran.desc }}
                                    <input type="hidden" name="id_ta" v-model="tahunajaran.id">
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Bulan</th>
                                  <th>
                                    <select class="form-select" v-model="variable.bulan">
                                        <option value="*">Pilihan</option>
                                        <option
                                                  v-for="eks in listbulan"
                                                  :key="eks.id"
                                                  :value="`${eks.id}`"
                                                >
                                                  {{ eks.nama }}
                                                </option>
                                    </select>
                                    <small class="text-danger" v-if="validation.bulan">{{ validation.bulan[0] }}</small>
                                  </th>
                                </tr>
                                <tr hidden>
                                  <th valign="top">Tahun</th>
                                  <th>
                                    <input type="number" v-model="variable.tahun" class="form-control" size="2">
                                    <small class="text-danger" v-if="validation.tahun">{{ validation.tahun[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Komentar</th>
                                  <th>
                                                <textarea 
                                                  v-model="variable.narasi"
                                                  name="narasi" 
                                                  cols="60" 
                                                  rows="5" 
                                                  style="width: 100%;"
                                                  maxlength="1000"
                                              ></textarea>
                                              <br>
                                              <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Gambar 1</th>
                                  <th>
                                    <a v-if="variable.foto1 != null "  :href="Api.defaults.image+'/public/report/' + variable.foto1" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;">
                                        <img :src="Api.defaults.image+'/public/report/' + variable.foto1" alt="" style="max-width: 100%;">
                                      </div>
                                    </a>
                                    <input type="hidden" name="foto1_old" id="foto1_old" v-model="variable.foto1">
                                    <input type="hidden" name="foto2_old" id="foto2_old" v-model="variable.foto2">
                                    <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange"  placeholder="upload raport"/>
                                    <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Gambar 2</th>
                                  <th>
                                    <a v-if="variable.foto2 != null "  :href="Api.defaults.image+'/public/report/' + variable.foto2" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;">
                                        <img :src="Api.defaults.image+'/public/report/' + variable.foto2" alt="" style="max-width: 100%;">
                                      </div>
                                    </a>
                                    <input type="file" accept=".jpg,.jpeg,.png" ref="foto2"  class="form-control"  :data-id="2"  @change="handleFileChange2"  placeholder="upload raport"/>
                                    <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th></th>
                                  <th>
                                    <div class="row">
                                      <div class="col-12">
                                        <button type="submit" class="btn btn-success btn-sm">
                                          Update
                                        </button>
                                        &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-bulanan-kb-history', params:{instructor:this.$route.params.instructor,id:siswa.data[0].id_student?siswa.data[0].id_student:1 },query: { kelass: $route.query.kelass } }"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                      
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted, watch, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import Api from "../../../api/Api";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });


      const listbulan = computed(() => {
            return store.getters["global/getBulan"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const narasi = computed(() => {
            return store.getters["narasikb/detail"];
      });




      //validation state

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
    
      const siswa = computed(() => {
            return store.getters["siswa/getSiswaAktifin"];
      });

      onMounted(async () => {
        // await store.dispatch("reg/getDetailSiswa", route.params.idsiswa);
        await store.dispatch("siswa/getSiswaAktifin", {
          page:1,
          perPage:10,
          search: route.params.idsiswa,
          kelas:'',
          unit:'',
          unitlevel:'',
          guru:route.params.instructor
  
        });
        await store.dispatch("global/getBulan");
        await store.dispatch("tahunajaran/getActive");
        await store.dispatch("narasikb/detail", route.params.id);
      });
  
      watch(
        () => store.state.narasikb.detail,
        (newsiswa) => {
          //console.log('newsiswa',newsiswa);
          if (newsiswa) {
            const {
                narasi,
                bulan,
                foto1,
                foto2,
            } = newsiswa;
            variable.narasi = narasi;
            variable.bulan = bulan;
            variable.foto1 = foto1;
            variable.foto2 = foto2;
          }
        }
      );

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        
      });

      async function handleFileChange(e) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        const maxSize = 1 * 1024 * 1024; // 1MB dalam byte
        if (image.size > maxSize) {
            e.target.value = "";
            this.variable.foto1 = null;
            Swal.fire({
                title: "OOPS!",
                text: "Ukuran file tidak boleh lebih dari 1MB!",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
            return;
        }
      }

      async function handleFileChange2(e) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        const maxSize = 1 * 1024 * 1024; // 1MB dalam byte
        if (image.size > maxSize) {
            e.target.value = "";
            this.variable.foto2 = null;
            Swal.fire({
                title: "OOPS!",
                text: "Ukuran file tidak boleh lebih dari 1MB!",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
            return;
        }
      }
      

      function saveLaporan() {
        //define variable
        console.log(" siswa ",siswa);
        let formData = new FormData();
        // formData.append('id_unit', siswa.id_unitlevel);
        formData.append('id_ta', this.tahunajaran.id);
        // formData.append('id_student', siswa.id);
        formData.append('bulan', this.variable.bulan);
        formData.append('tahun', this.variable.tahun);
        // formData.append('elemen', route.params.bidang);
        formData.append('narasi', this.variable.narasi);
        formData.append('foto1', this.variable.foto1);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "PUT");
        console.log(formData);
        //panggil actions "register" dari module "auth"
        store
          .dispatch("narasikb/update", {
            id: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            // router.push({ name: "admin-laporan-bulanan-kb-history" });
            router.push({
              name: 'admin-laporan-bulanan-kb-history',
              params: {
                id:  route.params.idsiswa,
                kelass:  route.params.kelass,
              },
              query: { kelass: route.query.kelass }
            });
            Swal.fire({
              icon: "success",
              title: "Simpan data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
      

      //return a state and function
      return {
        siswa, // <--
        listbulan,
        variable,
        handleFileChange,
        handleFileChange2,
        data,
        saveLaporan,
        validation,
        tahunajaran,
        narasi,
        Api
      };
    },
  };
  </script>