<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Medical Info</h5>
              <router-link  to="/medicalinfo/generate" class="btn btn-success btn-sm"
             >Generate </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show:</span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-0" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.id_unit" @change="changeUnit">
                    <option value="">-- select Unit --</option>
                    <option v-for="unit in units" :key="unit.id" :value="unit.id">
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.id_unitlevel" @change="changeUnitlevel">
                    <option value="">-- select Unitlevel --</option>
                    <option v-for="u in unitlevels" :key="u.id" :value="u.id">
                      {{ u.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.kelas_id" @change="changeKelas">
                    <option value="">-- select Kelas --</option>
                    <option v-for="u in kelas" :key="u.id" :value="u.id">
                      {{ u.classname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Data Lengkap</th>
                    <th>Unit</th>
                    <th class="text-center">Kelas</th>
                    <th class="text-center">Berat Badan</th>
                    <th class="text-center">Tinggi Badan</th>
                    <th class="text-center">Lingkar Kepala</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(m, index) in datamedicalinfo.data" :key="m.id" style="height: 1px">
                    <td>
                      {{ m.fullname }}
                      <!-- - {{m.idreg}}   - {{m.idstudents}}     -  - {{m.idmedical}}                       -->
                      <!-- <br />Unitlevel : {{ m.nama_unitlevel }} -->
                    </td>
                    <td>{{ m.nama_unit }}</td>
                    <td class="text-center">{{ m.classname }}</td>
                    <td class="text-center">
                      <div style="display: flex; justify-content: center; align-items: center">
                        <input
                          v-if="
                            user.roles[0].name == 'admin' ||
                            user.roles[0].name == 'TU' ||
                            user.roles[0].name == 'suster' ||
                            user.roles[0].name == 'wali kelas' ||
                            user.roles[0].name == 'wali kelas kbtk' ||
                            user.roles[0].name == 'wakil kurikulum' ||
                            user.roles[0].name == 'wakil kurikulum kbtk'
                          "
                          class="form-control form-control-sm"
                          type="text"
                          :value="m.weight ? m.weight : ''"
                          :data-id="m.id ? m.id : '' "
                          :id_reg="m.idreg"
                           :id_medical="m.idmedical"
                          @input="updateWeight"
                          @keydown.enter="focusNextInput($event, m, index)"
                          style="max-width: 50px; text-align: center"
                        />
                        <input v-else class="form-control form-control-sm" type="text" :value="m.weight" :data-id="m.id" @input="updateWeight" disabled style="max-width: 50px; text-align: center" />
                      </div>
                    </td>
                    <td>
                      <div style="display: flex; justify-content: center; align-items: center">
                        <input
                          v-if="
                            user.roles[0].name == 'admin' ||
                            user.roles[0].name == 'TU' ||
                            user.roles[0].name == 'suster' ||
                            user.roles[0].name == 'wali kelas' ||
                            user.roles[0].name == 'wali kelas kbtk' ||
                            user.roles[0].name == 'wakil kurikulum' ||
                            user.roles[0].name == 'wakil kurikulum kbtk'
                          "
                          class="form-control form-control-sm"
                          type="text"
                          :value="m.height ? m.height : ''"
                          
                          :id_medical = "m.idmedical"
                          :id_reg="m.idreg"
                          @input="updateHeight"
                          @keydown.enter="focusNextInput($event, m, index)"
                          style="max-width: 60px; text-align: center"
                        />
                        <input v-else type="text" class="form-control form-control-sm" :value="m.height" :data-id="m.id" @input="updateHeight" disabled style="max-width: 60px; text-align: center" />
                      </div>
                    </td>
                    <td>
                      <div style="display: flex; justify-content: center; align-items: center">
                        <input
                          v-if="
                            user.roles[0].name == 'admin' ||
                            user.roles[0].name == 'TU' ||
                            user.roles[0].name == 'suster' ||
                            user.roles[0].name == 'wali kelas' ||
                            user.roles[0].name == 'wali kelas kbtk' ||
                            user.roles[0].name == 'wakil kurikulum' ||
                            user.roles[0].name == 'wakil kurikulum kbtk'
                          "
                          class="form-control form-control-sm"
                          type="text"
                          :value="m.lingkar_kepala ? m.lingkar_kepala : ''"
                          :data-id="m.id ? m.id : '' "
                          :id_reg="m.idreg"
                            :id_medical = "m.idmedical"
                          @input="updateLingkarKepala"
                          @keydown.enter="focusNextInput($event, m, index)"
                          style="max-width: 50px; text-align: center"
                        />
                        <input v-else class="form-control form-control-sm" type="text" :value="m.lingkar_kepala" :data-id="m.id" @input="updateLingkarKepala" disabled style="max-width: 50px; text-align: center" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ datamedicalinfo.to }} dari {{ datamedicalinfo.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: datamedicalinfo.current_page === 1 }">
                  <a class="page-link" @click="changePage(datamedicalinfo.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in datamedicalinfo.links" :key="page" :class="{ 'page-item': true, active: page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: datamedicalinfo.current_page === datamedicalinfo.last_page }">
                  <a class="page-link" @click="changePage(datamedicalinfo.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router"; // Correct import from vue-router

export default {
  name: "PendaftaraktifIndex",

  setup() {
    const router = useRouter();
    const validation = ref([]);
    const store = useStore();
    const data = reactive({
      perPage: 30,
      search: "",
      unit: "",
      id_unit: "",
      unitlevel: "",
      id_unitlevel: "",
      kelas_id: "",
      pages: "medicalinfo-view",
    });

    onMounted(async () => {
      await store.dispatch("medicalinfo/getData");
      await store.dispatch("units/getAll");
      await store.dispatch("unitlevel/getAll");
      await store.dispatch("kelas/getClassname");
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const datamedicalinfo = computed(() => {
      return store.getters["medicalinfo/getData"];
    });

    const focusNextInput = (e, m, index) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-control");
        const currentIndex = Array.from(inputs).indexOf(targetInput);
        const columns = 3; // Number of columns in your table

        // Calculate the next index based on the current column
        const nextIndex = currentIndex + 1 < inputs.length ? currentIndex + 1 : (index + 1) * columns;

        inputs[nextIndex].focus();
      }
    };

    async function updateWeight(e) {
      const inputValue = e.target.value;
      // const id = e.target.attributes[2].nodeValue ? e.target.attributes[2].nodeValue : 0;
      const id = e.target.getAttribute('id_medical');
     
      const id_reg = e.target.getAttribute('id_reg');
      let formData = new FormData();
      formData.append("weight", inputValue);
      formData.append("id", id);
      formData.append("id_reg", id_reg);
      formData.append("_method", "POST");

      await store
        .dispatch("medicalinfo/updateWeight", {
          medicalId: id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    async function updateHeight(e) {
      const inputValue = e.target.value;
      // const id = e.target.attributes[2].nodeValue ? e.target.attributes[2].nodeValue : 0;
      const id = e.target.getAttribute('id_medical');
      const id_reg = e.target.getAttribute('id_reg');

      let formData = new FormData();
      formData.append("id", id);
      formData.append("id_reg", id_reg);
      formData.append("height", inputValue);
      formData.append("_method", "POST");

      await store
        .dispatch("medicalinfo/updateHeight", {
          medicalId: id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    async function updateLingkarKepala(e) {
      const inputValue = e.target.value;
      // const id = e.target.attributes[2].nodeValue ? e.target.attributes[2].nodeValue : 0;
      const id = e.target.getAttribute('id_medical');
      const id_reg = e.target.getAttribute('id_reg');

      let formData = new FormData();
      formData.append("lingkar_kepala", inputValue);
      formData.append("id", id);
      formData.append("id_reg", id_reg);
      formData.append("_method", "POST");

      await store
        .dispatch("medicalinfo/updateLingkarKepala", {
          medicalId: id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    const units = computed(() => {
      return store.getters["units/getAll"];
    });

    const perPageOptions = [10, 30, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("medicalinfo/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        ppdb: data.ppdb,
        status: data.status,
        kelas: data.kelas_id,
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        ppdb: data.ppdb,
        status: data.status,
        kelas: data.kelas_id,
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        ppdb: data.ppdb,
        status: data.status,
        kelas: data.kelas_id,
      });
    };

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    const changeUnit = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      if (data.id_unit) {
        await store.dispatch("unitlevel/getAll", data.id_unit);
      } else {
        console.error("data.id_unit is undefined or falsy");
      }
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });
    };

    const changeUnitlevel = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      if (data.id_unit) {
        await store.dispatch("unitlevel/getAll", data.id_unit);
      } else {
        console.error("data.id_unit is undefined or falsy");
      }
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });

      await store.dispatch("kelas/getClassname", data.id_unitlevel);
    };

    const changeKelas = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      if (data.id_unit) {
        await store.dispatch("unitlevel/getAll", data.id_unit);
      } else {
        console.error("data.id_unit is undefined or falsy");
      }
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });

      await store.dispatch("kelas/getClassname", data.id_unitlevel);
    };

    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const kelas = computed(() => {
      return store.getters["kelas/getClassname"];
    });

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      changePage,
      changePerPage,
      datamedicalinfo,
      perPageOptions,
      data,
      searchData,
      changeUnit,
      updateWeight,
      updateHeight,
      updateLingkarKepala,
      unitlevels,
      changeUnitlevel,
      units,
      user,
      focusNextInput,
      kelas,
      changeKelas,
    };
  },
};
</script>
