<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Anekdotal</h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updatetypay">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="tahunajaran">Tahun Ajaran</label>
                        <select class="form-control" v-model="form.tahunajaran_id">
                          <option v-for="option in tahunAjaranList" :key="option.id" :value="option.id">{{ option.desc }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Pilih Unit<small>*</small></label>
                        <select class="form-control" v-model="form.unit_id" @change="changeUnit">
                          <option value="">-- Pilih Unit --</option>
                          <option v-for="unit in parentmenu" :key="unit.id" :value="unit.id">
                          {{ unit.name }}
                        </option>
                        </select>
                        <small class="text-danger" v-if="validation.unit_id">{{ validation.unit_id[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Siswa <small>*</small></label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="searchTerm" 
                            @input="filterEkskul" 
                            :placeholder="detil.fullname"
                            :disabled="form.siswa == false "
                          />

                          <ul class="list-group" v-if="filteredSiswa.length">
                            <li 
                              class="list-group-item" 
                              v-for="eks in filteredSiswa" 
                              :key="eks.id_student" 
                              @click="selectEkskul(eks)">
                              {{ eks.fullname }} {{ eks.classname }}
                            </li>
                          </ul>

                        <small class="text-danger" v-if="validation.student_id">{{ validation.student_id[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="tanggal_peristiwa">Tanggal Peristiwa</label>
                        <input type="date" class="form-control" v-model="form.tanggal_peristiwa" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="peristiwa">Peristiwa</label>
                        <textarea class="form-control" v-model="form.peristiwa" :readonly="form.tindakan_bk !== null || form.tindakan_kesiswaan !== null"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="user.roles[0].name != 'wali kelas' && user.roles[0].name != 'akademik'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kategori Peristiwa<small></small></label>
                        <!-- <input type="text" v-model="menu.level"> -->
                        <select class="form-control" v-model="form.kategori_peristiwa" multiple style="height: 200px;"
                          @blur="levelUnit()">
                          <option value="">Pilih</option>
                          <option value="kedisiplinan">Kedisiplinan</option>
                          <option value="prilaku">Prilaku</option>
                        </select>
                       
                      </div>
                    </div>
                  </div>


                  

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="tindakan_guru">Tindakan Guru</label>
                        <textarea class="form-control" v-model="form.tindakan_guru" :readonly="form.tindakan_bk !== null || form.tindakan_kesiswaan !== null"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="user.roles[0].name != 'wali kelas' && user.roles[0].name != 'akademik'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="tindakan_bk">Tindakan BK</label>
                        <textarea class="form-control" v-model="form.tindakan_bk"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="user.roles[0].name != 'wali kelas' && user.roles[0].name != 'akademik'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="tindakan_kesiswaan">Tindakan Kesiswaan</label>
                        <textarea class="form-control" v-model="form.tindakan_kesiswaan"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="catatan">Catatan</label>
                        <textarea class="form-control" v-model="form.catatan"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-anekdotal' }" class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "typayIndex",
  components: {},
  data() {
    return {
      showanekdotal: false,
      filteredSiswa: [],
      searchTerm: '',
      
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const form = reactive({
      tahunajaran_id: "",
      unit_id: "",
      unitlevel_id: "",
      classroom_id: "",
      student_id: "",
      tanggal_peristiwa: "",
      peristiwa: "",
      kategori_peristiwa: "",
      instructor_id: "",
      tindakan_guru: "",
      tindakan_bk: "",
      tindakan_kesiswaan: "",
      catatan: "",
      siswa:false,
    });

    const data = reactive({

      pages: 'anekdotal-edit'

    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();




    //function register, fungsi ini di jalankan ketika form di submit
    function updatetypay() {
      //define variable


      let formData = new FormData();
      formData.append("tahunajaran_id", form.tahunajaran_id);
      formData.append("unit_id", form.unit_id);
      formData.append("student_id", form.student_id);
      formData.append("classroom_id", form.classroom_id);
      formData.append("unitlevel_id", form.unitlevel_id);
      formData.append("tanggal_peristiwa", form.tanggal_peristiwa);
      formData.append("peristiwa", form.peristiwa);
      formData.append("kategori_peristiwa", form.kategori_peristiwa);
      formData.append("instructor_id", form.instructor_id);
      formData.append("tindakan_guru", form.tindakan_guru); // Jika null, beri string kosong
      formData.append("tindakan_bk", form.tindakan_bk);
      formData.append("tindakan_kesiswaan", form.tindakan_kesiswaan);
      formData.append("catatan", form.catatan);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("anekdotal/update", {
          anekdotalId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-anekdotal" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("units/getAll");
      await store.dispatch("unitlevel/getByUnit",form.unit_id);
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("reg/getDetailIdStudent",route.params.id);
      await store.dispatch("masterkelas/getAll", form.unitlevel_id);
      await store.dispatch("anekdotal/getDetail", route.params.id);
      await store.dispatch("siswa/getSiswaAktif",{
        page: 1,
        perPage: 10000,
        search: '',
        kelas: '',
        unit: form.unit_id,
        unitlevel: '',
      });
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    const siswas = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["siswa/getSiswaAktif"];
    });

    // const changeUnit = async () => {
     
    //   await store.dispatch("unitlevel/getByUnit",form.unit_id);
    // };

    const detil = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["reg/getDetailIdStudent"];
    });

    const unitList = computed(() => {
      return store.getters["units/getAll"];
    });

    const changeUnitlevel = async () => {
    
      await store.dispatch("masterkelas/getAll", form.unitlevel_id);
    };

    const unitLevelList = computed(() => {
      return store.getters["unitlevel/getByUnit"];
    });

    const tahunAjaranList = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const changeKelas = async () => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: 100,
        search: data.search,
        kelas: form.classroom_id,
        unit: form.unit_id,
        unitlevel: form.unitlevel_id,
      });
    };
    const classroomList  = computed(() => store.getters["masterkelas/getAll"]);
    const studentList = computed(() => store.getters["siswa/getSiswaAktif"]);

    watch(
      () => store.state.anekdotal.details,
      (newanekdotal) => {
        console.log('ini',newanekdotal)
        if (newanekdotal) {
          const {
            tahunajaran_id,
          unit_id,
          unitlevel_id,
          classroom_id,
          student_id,
          tanggal_peristiwa,
          peristiwa,
          kategori_peristiwa,
          instructor_id,
          tindakan_guru,
          tindakan_bk,
          tindakan_kesiswaan,
          catatan,
          } = newanekdotal;
          form.tahunajaran_id = tahunajaran_id;
          form.unit_id = unit_id;
          form.unitlevel_id = unitlevel_id;
          form.classroom_id = classroom_id;
          form.student_id = student_id;
          form.tanggal_peristiwa = tanggal_peristiwa;
          form.peristiwa = peristiwa;
          form.instructor_id = instructor_id;
          form.tindakan_guru = tindakan_guru;
          form.tindakan_bk = tindakan_bk;
          form.tindakan_kesiswaan = tindakan_kesiswaan;
          form.catatan = catatan;
          form.kategori_peristiwa = kategori_peristiwa ? kategori_peristiwa.split(',') : [];
        }
      }
    );

    //computed


    //return a state and function
    return {
      updatetypay,
      data,
      changeKelas,
      form, // <-- state user
      validation, 
      studentList,
      unitList,
      unitLevelList,
      tahunAjaranList,
      classroomList,
      changeUnitlevel,
      parentmenu,
      siswas,
      detil,
      user
    };
  },
  methods: {
    async changeUnit() {
      const selectedValues = this.form.unit_id;

      if (selectedValues == ' ' || selectedValues == null || selectedValues == '') {
        this.form.siswa = false;
      } else {
        this.form.siswa = true;
      }

      // Reset nilai searchTerm dan filteredSiswa
      this.searchTerm = ''; // Reset searchTerm
      this.filteredSiswa = []; // Reset hasil filter siswa

      await this.$store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: 10000,
        search: '',
        kelas: '',
        unit: selectedValues,
        unitlevel: '',
      });
    },
    async checkMaxLength() {
      let tahunStr = this.form.tahun.toString();
      if (tahunStr.length > 4) {
        this.form.tahun = tahunStr.slice(0, 4);
      }
      // console.log(" panjang ",tahunStr.length);
    },
    filterEkskul() {
      if (this.searchTerm === '') {
        this.filteredSiswa = [];
        return;
      }

      // Filter the ekskul list based on the search term
      this.filteredSiswa = this.siswas.data.filter(eks => 
        eks.fullname.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    selectEkskul(eks) {
      this.form.classroom_id = `${eks.idclassrooms}`;

    this.form.unitlevel_id = `${eks.id_unitlevel}`;
      // Set the selected ekskul
      this.form.student_id = `${eks.id_student}`;
      
      // Set the search term to the selected ekskul
      this.searchTerm = `${eks.fullname} (${eks.classname	})`;

      // Clear the filtered list
      this.filteredSiswa = [];

      // Call changeUnit or any method you need to trigger
      // this.changeUnit();
    }
  },
};
</script>