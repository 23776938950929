<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Detail Siswa</h5>
                        <router-link
                          :to="{
                            name: 'admin-siswaaktif',
                            query: { search: $route.query.search ?? ''} // Menggunakan nilai dari data.search
                          }"
                          class="btn btn-danger btn-sm"
                        >
                          kembali
                        </router-link>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
               
                <div class="col-lg-12 mt-lg-0 mt-4">
                  <div class="card card-body" id="profile">
                    <div class="row">
                      <div class="col-sm-auto col-4">
                        <div class="avatar avatar-xl position-relative">
                          <img
                            :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.photopath}`"
                            class="w-100 border-radius-lg shadow-sm"
                            v-if="siswa.photopath != null"
                          />
                          <img
                            src="https://via.placeholder.com/100x100"
                            alt="bruce"
                            class="w-100 border-radius-lg shadow-sm"
                            v-else
                          />
                        </div>
                      </div>
                      <div class="col-sm-auto col-8 my-auto">
                        <div class="h-100">
                          <h5 class="mb-1 font-weight-bolder">
                            {{ siswa.fullname ? siswa.fullname : "" }}
                          </h5>
                          <p class="mb-0 font-weight-bold text-sm">
                            {{ siswa.unit }} - {{ siswa.kelas }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card mt-4" id="profile-siswa">
                    
                    <div class="card-body pt-0">
                        <form @submit.prevent="update">
                        <div class="row">
                            <div class="col-sm-4 col-4">
                            <label class="form-label">Nama Lengkap</label>
                            <div class="input-group">
                                <input
                                id="firstName"
                                name="firstName"
                                class="form-control"
                                type="text"
                                v-model="siswa.fullname"
                                
                                />
                               
                            </div>
                            <small class="text-danger" v-if="validation.fullname">{{
                  validation.fullname[0]
                }}</small>
                            </div>
                            <div class="col-sm-4 col-4">
                            <label class="form-label">Tanggal Lahir</label>
                            <div class="input-group">
                                <input
                                id="lastName"
                                name="lastName"
                                class="form-control"
                                type="date"
                                v-model="siswa.birthdate"

                                />
                               
                            </div>
                            <small class="text-danger" v-if="validation.birthdate">{{
                  validation.birthdate[0]
                }}</small>
                            </div>
                            <div class="col-sm-4 col-4">
                            <label class="form-label">Tempat Lahir</label>
                            <div class="input-group">
                                <input
                                id="lastName"
                                name="lastName"
                                class="form-control"
                                type="text"
                                v-model="siswa.birthplace"
                                
                                />
                              
                            </div>
                            <small class="text-danger" v-if="validation.birthplace">{{
                  validation.birthplace[0]
                }}</small>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                            <label class="form-label">Nama Lengkap Ayah</label>
                            <div class="input-group">
                                <input
                                id="firstName"
                                name="firstName"
                                class="form-control"
                                type="text"
                                v-model="siswa.namaayah"
                              
                                />
                            </div>
                            <small class="text-danger" v-if="validation.namaayah">{{
                  validation.namaayah[0]
                }}</small>
                            </div>
                            <div class="col-6">
                            <label class="form-label">Nama Lengkap Ibu</label>
                            <div class="input-group">
                                <input
                                id="firstName"
                                name="firstName"
                                class="form-control"
                                type="text"
                                v-model="siswa.namaIbu"
                               
                                />
                                <small class="text-danger" v-if="validation.namaibu">{{
                  validation.namaibu[0]
                }}</small>
                            </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        update
                      </button>
                     
                    </div>
                  </div>
                        </form>
                    
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive, onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import { useRoute } from "vue-router";
  import { useRouter } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "PendaftarAktifIndex",
    components: {},
    data() {
      return {
        showMenu: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const siswa = reactive({
        fullname: "",
        pathfolder: "",
        photopath: "",
        unit: "",
        noreg: "",
        kelas: "",
        nickname: "",
        birthdate: "",
        birthplace: "",
        gender: "",
        nik: "",
        nikk: "",
        wni: "",
        lang: "",
        namaayah: "",
        agamaayah: "",
        birthdateayah: "",
        birthplaceeayah: "",
        wniayah: "",
        pendidikanayah: "",
        emailayah: "",
        nokontakayah: "",
        namaIbu: "",
        birthdateIbu: "",
        birthplaceeIbu: "",
        wniIbu: "",
        pendidikanIbu: "",
        agamaIbu: "",
        emailIbu: "",
        nokontakIbu: "",
        alamat: "",
        rt: "",
        rw: "",
        postalcode: "",
        transportasi: "",
        jarak: "",
        jam: "",
        menit: "",
        darah: "",
        riwayatkelahiran: "",
        beratbadan: "",
        tinggibadan: "",
        alergiobat: "",
        alergimakanan: "",
        kkpath: "",
        aktapath: "",
        raport: "",
        suratpernyataan: "",
      });
  
      const data = {
        pages: "siswaaktif-edit",
      };
      //validation state
      const validation = ref([]);
  
      const router = useRouter();
  
      //store vuex
      const store = useStore();
      //route
  
      const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
  
      onMounted(async () => {
        await store.dispatch("reg/getDetailSiswa", route.params.id);
        const permissions = localStorage.getItem("permission");
  
        if (!permissions.includes(data.pages)) {
          router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
        }
      });

      function update() {
      //define variable


        let formData = new FormData();
        formData.append("fullname", this.siswa.fullname);
        formData.append("birthdate", this.siswa.birthdate);
        formData.append("birthplace",this.siswa.birthplace);
        formData.append('namaayah',this.siswa.namaayah);
        formData.append('namaibu', this.siswa.namaIbu);
        formData.append("_method", "POST");

        console.log(formData);
        //panggil actions "register" dari module "auth"
        store
          .dispatch("siswa/update", {
            regId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ 
              name: "admin-siswaaktif", 
              query: { search: route.query.search } // Ambil search dari URL saat ini
          });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });

            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
          

            validation.value = error.errors;
           
          });
      }
  
      watch(
        () => store.state.reg.pesertadetail,
        (newMenu) => {
          if (newMenu) {
            const {
              fullname,
              pathfolder,
              photopath,
              unitlevel,
              noreg,
              nickname,
              birthdate,
              birthplace,
              gender,
              nik,
              nikk,
              wni,
              lang,
              orang_tua,
              tempat_tinggal,
              medical_info,
              kkpath,
              aktapath,
              suratpath,
              raportpath,
            } = newMenu;
            (siswa.fullname = fullname),
              (siswa.unit = unitlevel.unit.name),
              (siswa.kelas = unitlevel.kelas.classname),
              (siswa.nickname = nickname),
              (siswa.noreg = noreg),
              (siswa.birthdate = birthdate),
              (siswa.birthplace = birthplace),
              (siswa.gender = gender),
              (siswa.nik = nik),
              (siswa.nikk = nikk),
              (siswa.wni = wni),
              (siswa.lang = lang),
              (siswa.namaayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.fullname
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.fullname
                  : ""),
              (siswa.agamaayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.religion
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.religion
                  : ""),
              (siswa.birthdateayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.birthdate
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.birthdate
                  : ""),
              (siswa.birthplaceeayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.birthplace
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.birthplace
                  : ""),
              (siswa.wniayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.wni
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.wni
                  : ""),
              (siswa.pendidikanayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.edulevel
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.edulevel
                  : ""),
              (siswa.emailayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.email
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.email
                  : ""),
              (siswa.nokontakayah =
                orang_tua[0].ayah != null
                  ? orang_tua[0].ayah.cellphone
                  : orang_tua[1].ayah != null
                  ? orang_tua[1].ayah.cellphone
                  : ""),
              (siswa.namaIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.fullname
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.fullname
                  : ""),
              (siswa.agamaIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.religion
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.religion
                  : ""),
              (siswa.birthdateIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.birthdate
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.birthdate
                  : ""),
              (siswa.birthplaceeIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.birthplace
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.birthplace
                  : ""),
              (siswa.wniIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.wni
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.wni
                  : ""),
              (siswa.pendidikanIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.edulevel
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.edulevel
                  : ""),
              (siswa.emailIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.email
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.email
                  : ""),
              (siswa.nokontakIbu =
                orang_tua[0].ibu != null
                  ? orang_tua[0].ibu.cellphone
                  : orang_tua[1].ibu != null
                  ? orang_tua[1].ibu.cellphone
                  : ""),
              (siswa.alamat = tempat_tinggal.address),
              (siswa.rt = tempat_tinggal.rt_number),
              (siswa.rw = tempat_tinggal.rw_number),
              (siswa.postalcode = tempat_tinggal.postalcode),
              (siswa.jarak = tempat_tinggal.distance_km),
              (siswa.jam = tempat_tinggal.distance_hour),
              (siswa.menit = tempat_tinggal.distance_minute),
              (siswa.darah = medical_info ? medical_info.blood_grp : "-"),
              (siswa.riwayatkelahiran = medical_info
                ? medical_info.birth_histry
                : "-"),
              (siswa.beratbadan = medical_info ? medical_info.weight : "-"),
              (siswa.height = medical_info ? medical_info.height : "-"),
              (siswa.alergiobat = medical_info
                ? medical_info.medic_allergy
                : "-"),
              (siswa.alergimakanan = medical_info
                ? medical_info.food_allergy
                : "-"),
              (siswa.pathfolder = pathfolder);
            siswa.photopath = photopath;
            siswa.kkpath = kkpath;
            siswa.aktapath = aktapath;
            siswa.raport = raportpath;
            siswa.suratpernyataan = suratpath;
          }
        }
      );
  
      //return a state and function
      return {
        siswa, // <-- state user
        validation,
        data,
        update
      };
    },
  };
  </script>