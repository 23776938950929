<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Daftar KKTP Mata Pelajaran</h5>
              <router-link v-if="datapermissions.includes('managementkelas-create') && user.roles[0].name == 'admin'" to="/kktp/generate"
              class="btn btn-success btn-sm mx-2">Generate Nilai KKTP</router-link>
              <!-- <router-link v-if="datapermissions.includes('matapelajaran-create')" to="/matapelajaran/tambah" class="btn btn-primary btn-sm">Tambah</router-link> -->
            </div>
          </div>
          
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"><i class="fa fa-search"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col ms-auto my-auto mr-5">
                <div class="form-group">
                  <label for="">Tahun Ajaran</label>
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="">-- select tahun ajaran --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                  <!-- {{ tas.desc ? tas.desc : '...' }} -->
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">

              </div>

            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <!-- <th class="text-left">Unit</th>
                    <th class="text-center">Kode</th> -->
                    <th class="text-left">Mata Pelajaran</th>
                    <th class="text-center" width="180">Level</th>
                    <th class="text-center" width="180">KKTP</th>
                    <!-- <th class="text-center">#</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in matapelajarans" :key="m.id" style="height: 1px">
                    <!-- <td class="text-left">{{ m.unit ? m.unit.name : "" }}</td>
                    <td class="text-center">{{ m.kode_mapel }}</td> -->
                    <td class="text-left">{{ m.nama_mapel }}</td>
                    <td class="text-center" width="180">{{ m.numrule }}</td>
                    <td align="center" width="180">
                      <input  
                          class="form-control form-control-sm"
                          :value="m.kktp ? m.kktp.kktp:''"
                          :id_mapel="m.id"
                          :unitlevel="m.unitlevel"
                          @input="updateWeight"
                          @keydown.enter="focusNextInput($event, m, index)"
                          style="width: 70px;text-align: center">
                      
                    </td>
                    <!-- <td class="text-center">
                      <router-link v-if="datapermissions.includes('matapelajaran-edit')" :to="{ name: 'admin-matapelajaran-edit', params: { id: m.id } }" class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>
                      <button v-if="datapermissions.includes('matapelajaran-delete')" class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.id)"><i class="fas fa-trash"></i></button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ matapelajarans.to }} dari {{ matapelajarans.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: matapelajarans.current_page === 1 }">
                  <a class="page-link" @click="changePage(matapelajarans.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in matapelajarans.links" :key="page" :class="{ 'page-item': true, active: page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: matapelajarans.current_page === matapelajarans.last_page }">
                  <a class="page-link" @click="changePage(matapelajarans.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default {
  name: "matapelajaranIndex",

  setup() {
    const store = useStore();
    const validation = ref([]);
    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: "",
      pages: "kktp-view",
    });

    const router = useRouter();

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    onMounted(async () => {
      // await store.dispatch("tahunajaran/getActive");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("matapelajaran/getDatakktp");
      await store.dispatch("user/getDataPermission");

      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const matapelajarans = computed(() => {
      return store.getters["matapelajaran/getDatakktp"];
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("matapelajaran/getDatakktp", {
        page: page,
        perPage: jumlah,
        search: pencarian,
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("matapelajaran/getDatakktp", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("matapelajaran/getDatakktp", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
      });

      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    const changeTa = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("matapelajaran/getDatakktp", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,


      });
    };

    function confirmDelete(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch("matapelajaran/destroy", id).then(() => {
            store.dispatch("matapelajaran/getData");
            //
            // router.push({ name: "admin-matapelajaran" });
            router.push({ name: "admin-matapelajaran" });
            //alert
            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Dihapus!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    }

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    async function updateWeight(e) {
      const inputValue = e.target.value;
      const id = e.target.getAttribute('id_mapel');
      const id_unitlevel = e.target.getAttribute('unitlevel');
      
      let formData = new FormData();
      formData.append("kktp", inputValue);
      formData.append("id_unit", id_unitlevel);
      formData.append("id_mapel", id);
      formData.append("_method", "POST");
      console.log("formData "+formData);
      // console.log("id "+id);
      await store
        .dispatch("matapelajaran/updatekktp", {
          medicalId: id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    const focusNextInput = (e, m, index) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-control");
        const currentIndex = Array.from(inputs).indexOf(targetInput);
        const columns = 3; // Number of columns in your table

        // Calculate the next index based on the current column
        const nextIndex = currentIndex + 1 < inputs.length ? currentIndex + 1 : (index + 1) * columns;

        inputs[nextIndex].focus();
      }
    };

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      matapelajarans,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      datapermissions,
      tas,
      user,
      updateWeight,
      focusNextInput,
      changeTa
    };
  },
};
</script>
