<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Aturan Nilai</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveAturanNilai">

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Aturan Nilai <span
                            class="text-danger">*</span></label>
                        <input type="text" placeholder="Nama Aturan Nilai" class="form-control"
                          v-model="aturannilai.nama_aturan_nilai" />
                        <small class="text-danger" v-if="validation.nama_aturan_nilai">{{
                          validation.nama_aturan_nilai[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="aturannilai.id_ta" @change="getStatus">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id"
                            :status="tahunajaran.status_tahun_ajaran">{{
                              tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Unit <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="aturannilai.id_unit" @change="changeUnit">
                          <option value="">-- select Units --</option>
                          <option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_unit">{{ validation.id_unit[0] }}</small>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <label for="exampleFormControlInput1">Show Publikasi <span
                        class="text-danger">*</span></label>
                      <div v-for="level in levels" :key="level.id">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="aturannilai.show_publikasi"
                            :value="level.id">
                          <label class="custom-control-label">{{ level.name }}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Kepala Sekolah <span
                            class="text-danger">*</span></label>
                        <input type="text" placeholder="Nama Kepala Sekolah" class="form-control"
                          v-model="aturannilai.nama_kepala_sekolah" />
                        <small class="text-danger" v-if="validation.nama_kepala_sekolah">{{
                          validation.nama_kepala_sekolah[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Wakil Kurikulum <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Wakil Kurikulum" class="form-control"
                          v-model="aturannilai.wakil_kurikulum" />
                        <small class="text-danger" v-if="validation.wakil_kurikulum">{{ validation.wakil_kurikulum[0]
                          }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="aturannilai.id_unit !== 1">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Publikasi Raport Mid <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_awal_publikasi_raport_mid" />

                        <small class="text-danger" v-if="validation.tanggal_awal_publikasi_raport_mid">{{
                          validation.tanggal_awal_publikasi_raport_mid[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Publikasi Raport Mid <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_akhir_publikasi_raport_mid" />

                        <small class="text-danger" v-if="validation.tanggal_akhir_publikasi_raport_mid">{{
                          validation.tanggal_akhir_publikasi_raport_mid[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Publikasi Raport Semester <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_awal_publikasi_raport_semester" />

                        <small class="text-danger" v-if="validation.tanggal_awal_publikasi_raport_semester">{{
                          validation.tanggal_awal_publikasi_raport_semester[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Publikasi Raport Semester <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_akhir_publikasi_raport_semester" />

                        <small class="text-danger" v-if="validation.tanggal_akhir_publikasi_raport_semester">{{
                          validation.tanggal_akhir_publikasi_raport_semester[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row" v-if="aturannilai.id_unit !== 1 && aturannilai.status !== 'ganjil'">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Publikasi Surat Kelulusan <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_awal_publikasi_raport_kelulusan" />

                        <small class="text-danger" v-if="validation.tanggal_awal_publikasi_raport_kelulusan">{{
                          validation.tanggal_awal_publikasi_raport_kelulusan[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Publikasi Surat Kelulusan <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_akhir_publikasi_raport_kelulusan" />

                        <small class="text-danger" v-if="validation.tanggal_akhir_publikasi_raport_kelulusan">{{
                          validation.tanggal_akhir_publikasi_raport_kelulusan[0] }}</small>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="row mb-3">
                    <div class="col-2" v-for="unitlevel in unitlevels" :key="unitlevel.id">

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="aturannilai.id_unitlevels"
                          :value="unitlevel.id">
                        <label class="custom-control-label">{{ unitlevel.name }}</label>
                      </div>

                    </div>
                  </div> -->

                  <div class="row">
                    <div class="col-md-6" v-if="aturannilai.id_unit !== 1">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Titi mangsa mid <span class="text-danger">*</span></label>
                        <input type="date" placeholder="Titi mangsa mid" class="form-control"
                          v-model="aturannilai.titimangsa_mid" />
                        <small class="text-danger" v-if="validation.titimangsa_mid">{{ validation.titimangsa_mid[0]
                          }}</small>
                      </div>
                    </div>
                    <div :class="aturannilai.id_unit !== 1 ? 'col-md-6' : 'col-md-12'">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Titi mangsa akhir <span
                            class="text-danger">*</span></label>
                        <input type="date" placeholder="Titi mangsa akhir" class="form-control"
                          v-model="aturannilai.titimangsa_akhir" />
                        <small class="text-danger" v-if="validation.titimangsa_akhir">{{ validation.titimangsa_akhir[0]
                          }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6" v-if="aturannilai.id_unit !== 1">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Jenis Penilaian Aktif <span
                            class="text-danger">*</span></label>
                        <select class="form-control" v-model="aturannilai.jenis_penilaian_aktif">
                          <option value="">-- pilih --</option>
                          <option value="mid">Mid Semester</option>
                          <option value="akhir">Akhir Semester</option>
                        </select>
                        <small class="text-danger" v-if="validation.jenis_penilaian_aktif">{{
                          validation.jenis_penilaian_aktif[0] }}</small>
                      </div>
                    </div>

                   
                  </div>

               

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-aturannilai' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2";

// import Select2 from 'vue3-select2-component';
// import Select2MultipleControl from 'v-select2-multiple-component';

export default {
  name: "userIndex",
  // components: {
  //   Select2MultipleControl
  // },
  data() {
    return {
      showuser: false,
    };
  },



  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    $(this.$refs.select2Input).select2();
    // eslint-disable-next-line no-unused-vars
  },


  setup() {
    //user state
    const aturannilai = reactive({
      nama_aturan_nilai: '',
      id_ta: '',
      nama_kepala_aturannilai: '',
      wakil_kurikulum: '',
      jenis_penilaian_aktif: '',
      nama_wilayah_rapot: '',
      id_unit: '',
      titimangsa_mid: '',
      titimangsa_akhir: '',
      tanggal_awal_publikasi_raport_mid: '',
      tanggal_akhir_publikasi_raport_mid: '',
      tanggal_awal_publikasi_raport_semester: '',
      tanggal_akhir_publikasi_raport_semester: '',
      tanggal_awal_publikasi_raport_kelulusan: '',
      tanggal_akhir_publikasi_raport_kelulusan: '',
      id_unitlevels: [],
      show_publikasi: [],
      status: ''
    });

    const data = reactive({

      pages: 'aturanpenilaian-create',

    });


    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();



    //function register, fungsi ini di jalankan ketika form di submit
    function saveAturanNilai() {
      // console.log('aturan', aturannilai.id_unitlevels);

      let formData = new FormData();
      formData.append('nama_aturan_nilai', this.aturannilai.nama_aturan_nilai)
      formData.append('id_ta', this.aturannilai.id_ta)
      formData.append('id_unit', this.aturannilai.id_unit)
      formData.append('jenis_penilaian_aktif', this.aturannilai.jenis_penilaian_aktif)
      formData.append('nama_wilayah_rapot', this.aturannilai.nama_wilayah_rapot)
      formData.append('nama_kepala_sekolah', this.aturannilai.nama_kepala_sekolah)
      formData.append('wakil_kurikulum', this.aturannilai.wakil_kurikulum)
      formData.append('tanggal_awal_publikasi_raport_mid', this.aturannilai.tanggal_awal_publikasi_raport_mid)
      formData.append('tanggal_akhir_publikasi_raport_mid', this.aturannilai.tanggal_akhir_publikasi_raport_mid)
      formData.append('tanggal_awal_publikasi_raport_semester', this.aturannilai.tanggal_awal_publikasi_raport_semester)
      formData.append('tanggal_akhir_publikasi_raport_semester', this.aturannilai.tanggal_akhir_publikasi_raport_semester)
      formData.append('tanggal_awal_publikasi_raport_kelulusan', this.aturannilai.tanggal_awal_publikasi_raport_kelulusan)
      formData.append('tanggal_akhir_publikasi_raport_kelulusan', this.aturannilai.tanggal_akhir_publikasi_raport_kelulusan)
      // Array.from(this.aturannilai.id_unitlevels).forEach(id_unitlevels => {
      //   formData.append("id_unitlevels[]", id_unitlevels);
      // })
      formData.append("id_unitlevels[]", this.aturannilai.id_unitlevels);
      formData.append("show_publikasi[]", this.aturannilai.show_publikasi);

      formData.append('titimangsa_mid', this.aturannilai.titimangsa_mid)
      formData.append('titimangsa_akhir', this.aturannilai.titimangsa_akhir)
      console.log('data form', formData);
      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("aturannilai/store",
          formData,
        )
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-aturannilai" });
          Swal.fire({
            icon: 'success',
            title: 'Tambah data berhasil',
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch('tahunajaran/getAll'),
        await store.dispatch('unitlevel/getAll'),
        await store.dispatch('unitlevel/getAlls'),
        await store.dispatch('guru/getAll'),
        await store.dispatch('units/getAll');
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const getStatus = (event) => {
      const selectedIndex = event.target.selectedIndex;
      const selectedOption = event.target.options[selectedIndex];
      const status = selectedOption.getAttribute('status');
      aturannilai.status = status
      // console.log('Status tahun ajaran yang dipilih:', status);
    }

    //computed
    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const levels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const changeUnit = async () => {
      await store.dispatch("unitlevel/getAll",
        aturannilai.id_unit
      );
    };

    const changeShow = async () => {
      await store.dispatch("unitlevel/getAll",
        aturannilai.id_unit
      );
    };

    const formattedUnitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      // console.log("ini konsole ",unitlevels);
      return unitlevels.value.map((item) => ({
        id: item.id,
        text: item.name || '', // Show name if exists, otherwise an empty string
      }));
    });

    const units = computed(() => {
      return store.getters["units/getAll"];
    });

    const gurus = computed(() => {
      return store.getters["guru/getAll"];
    });



    //return a state and function
    return {
      aturannilai, // <-- state user
      validation, // <-- state validation
      saveAturanNilai,
      tahunajarans,
      unitlevels,
      units,
      gurus,
      formattedUnitlevels,
      changeUnit,
      getStatus,
      data,
      levels,
      changeShow 

    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>