<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit User</h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateUser">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Username</label>
                        <input type="text" placeholder="Username" class="form-control" v-model="user.username" />
                        <small class="text-danger" v-if="validation.username">{{ validation.username[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Email</label>
                        <input type="text" placeholder="Email" class="form-control" v-model="user.email" />
                        <small class="text-danger" v-if="validation.email">{{ validation.email[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Password</label>
                        <input type="password" placeholder="Password" class="form-control" v-model="user.password" />
                        <small class="text-danger" v-if="validation.password">{{ validation.password[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-2" v-for="role in roles" :key="role.id">
                      <div class="custom-control custom-radio mb-3">
                        <input :name="`custom-radio-${role.id}`" class="custom-control-input"
                          :id="`customRadio${role.id}`" type="radio" :value="role.id.toString()" v-model="user.roles">
                        <label class="custom-control-label" :for="`customRadio${role.id}`">{{ role.name }}</label>
                      </div>
                      <small class="text-danger" v-if="validation.roles">{{ validation.roles[0] }}</small>
                    </div>

                    <!-- Tambahkan radio untuk "Hapus Data" -->
                    <div class="col-2">
                      <div class="custom-control custom-radio mb-3">
                        <input name="custom-radio-hapus" class="custom-control-input"
                          id="customRadioHapus" type="radio" value="hapus" v-model="user.roles">
                        <label class="custom-control-label" for="customRadioHapus">Hapus Data</label>
                      </div>
                    </div>
                  </div>



                  <!-- pegawai -->
                  <hr>
                  <h5>Profile Pegawai</h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Lengkap</label>
                        <input type="text" placeholder="nama_lengkap" class="form-control"
                          v-model="user.nama_lengkap" />
                        <small class="text-danger" v-if="validation.nama_lengkap">{{ validation.nama_lengkap[0]
                          }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label>Unit Kerja Pegawai</label>
                    <div class="col-2" v-for="u in units" :key="u.id">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="user.units" :value="u.id"
                          :id="`check-${u.id}`">
                        <label class="custom-control-label" :for="`check-${u.id}`">{{ u.name }}</label>
                        <br />
                      </div>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label>Apakah Pegawai Ini Guru ?</label>
                    <div class="col-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="user.guru"
                          :checked="user && user.guru && user.guru.id !== null">
                        <label class="custom-control-label">Ya</label>
                        <br />
                      </div>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>



                  <div class="row">
                    <div class="col-md-12" v-if="user.username != null">
                      <img
                        :src="user.photo ? user.photo : 'https://ui-avatars.com/api/?name=' + user.username.replace(' ', '+') + '&background=4e73df&color=ffffff&size=100'"
                        alt="">
                    </div>
                    <div class="col-md-12" v-else>
                      <img
                        :src="user.photo ? user.photo : 'https://ui-avatars.com/api/?name=user&background=4e73df&color=ffffff&size=100'"
                        alt="">
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="">Photo Profile</label>
                        <input type="file" @change="handleFilePhotoChange" class="form-control">
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-user' }" class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "UserIndex",
  components: {},
  data() {
    return {
      showUser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const user = reactive({
      username: '',
      email: '',
      password: '',
      roles: '',
      nama_lengkap: '',
      unit: [],
      photo: '',
      guru: '',
      units: []
    });

    const data = reactive({
      pages: "user-edit"

    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updateUser() {
      //define variable
      let username = user.username;
      let email = user.email;
      let password = user.password;
      let roles = user.roles;
      let nama_lengkap = user.nama_lengkap;
      // let unit = user.unit;
      let guru = user.guru;

      let formData = new FormData();

      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("roles", roles);
      formData.append("nama_lengkap", nama_lengkap);
      formData.append("unit[]", user.units);
      formData.append("guru", guru);
      formData.append("_method", "POST");

      // //console.log("formdata", formData);

      //panggil actions "register" dari module "auth"
      store
        .dispatch("user/update", {
          userId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-user" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }



    onMounted(async () => {
      await store.dispatch("roles/getAll");
      await store.dispatch("units/getSemua");
      await store.dispatch("user/getDetailuser", route.params.id);
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    watch(
      () => store.state.user.detailuser,
      (newUser) => {
        //console.log('newuser',newUser);
        if (newUser) {
          const {
            username,
            email,
            password,
            // roles,
            model_has_roles,
            pegawai,
            // unit,
            guru,
          } = newUser;
          user.username = username ?? '';
          user.email = email ?? '';
          user.password = password;
          user.roles = model_has_roles ? model_has_roles.role_id : null;
          user.nama_lengkap = pegawai ? pegawai.nama_lengkap : '';
          user.units = pegawai ? JSON.parse(pegawai.unit) : [""];
          // //console.log('unitku',user.units);
          user.guru = guru;
          user.photo = pegawai ? pegawai.photo : '';
        }
      }
    );

    //computed
    const roles = computed(() => {
      //panggil getter dengan nama "getParent" di module "user" vuex
      return store.getters["roles/getAll"];
    });

    const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "user" vuex
      return store.getters["units/getAll"];
    });

    //return a state and function
    return {
      user, // <-- state user
      validation, // <-- state validation
      updateUser,
      roles, // <-- hook toast
      units,
      data
    };
  },
};
</script>